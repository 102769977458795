<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');
  body {
    padding: 0;
    margin: 0;
    background: #000;
    color: #fff;
    font-size: 14px;

    a {
      color: #fff;
      text-decoration: underline;
    
      &:hover {
        text-decoration: none;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  #app {
    

    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;  
  }
</style>
